<template>
  <el-card>
    <!-- HEADER -->
    <div slot="header" class="clearfix">
      <h3 style="line-height: 36px">Developer</h3>
      <br />
      <small>The system will only show the last 3 days of records from the cache.</small>

      <!-- BUTTONS -->
      <div style="float: right">
        <el-button type="danger" size="mini" @click="clearData">Clear Data</el-button>
        <el-button type="info" size="mini" @click="refreshData">Refresh Data</el-button>
      </div>
    </div>
    <!-- NO OF RECORDS -->
    <div class="block">
      <div style="display: inline-block">
        <span>{{ noOfItems }} records</span>
      </div>
    </div>
    <!-- DATA TABLE -->
    <div style="margin-top: 20px">
      <el-table
        size="mini"
        v-loading="loading"
        element-loading-text="Loading data.."
        :data="pagedData"
        style="width: 100%"
      >
        <el-table-column type="expand">
          <template slot-scope="props">
            <table class="flatten">
              <template>
                <tr :key="`${props.row.id}-${key}`" v-for="(item, key) in flatten(props.row?.data)">
                  <th>{{ key }}</th>
                  <td>{{ item }}</td>
                </tr>
              </template>
            </table>
          </template>
        </el-table-column>
        <!-- TIME -->
        <el-table-column label="Time" min-width="150">
          <template slot-scope="scope">
            <strong>{{ scope.row.Timestamp | fromNow }}</strong>
            <br />
            <small>{{ scope.row.Timestamp | moment }}</small>
          </template>
        </el-table-column>
        <!-- TYPE -->
        <el-table-column prop="type" label="Type" min-width="100" />
        <!-- URL -->
        <el-table-column prop="url" label="URL" min-width="250">
          <template slot-scope="scope">{{
            scope.row.data?.request?.originalUrl || scope.row.data?.url
          }}</template>
        </el-table-column>
        <!-- RESPONSE STATUS -->
        <el-table-column label="Response status" min-width="200">
          <template slot-scope="scope">{{
            scope.row.data?.response?.status || scope.row.data?.response?.message
          }}</template>
        </el-table-column>
        <!-- EVENT NAME -->
        <el-table-column label="Event name" min-width="250">
          <template slot-scope="scope">{{ scope.row.data?.payload?.event }}</template>
        </el-table-column>
      </el-table>
    </div>
  </el-card>
</template>
<script>
import moment from "moment";
import gql from "graphql-tag";
import _ from "lodash";
import { flatten } from "@/helpers/flatten";
export default {
  data() {
    return {
      loading: false,
      pagedData: [],
      totalRecordCount: 0,
      pageSize: 100000,
      currentPage: 1,
    };
  },
  computed: {
    noOfItems() {
      return this.totalRecordCount;
    },
  },
  filters: {
    moment: function (date) {
      return moment(date).local().format("D MMM YY h:mm A");
    },
  },
  methods: {
    flatten,
    refreshData() {
      this.loading = true;
      this.$apollo.queries.getDevCenterLogs.refetch();
    },
    clearData() {
      this.$confirm("This action will permanently delete the data. Continue?", "Warning", {
        confirmButtonText: "OK",
        cancelButtonText: "Cancel",
        type: "warning",
      }).then(() => {
        this.loading = true;
        // @todo: should be a mutation
        this.$apollo
          .query({
            query: gql`
              query {
                Bot {
                  flushDevCenterLogs
                }
              }
            `,
          })
          .catch((error) => {
            this.$notify.error({
              title: "Error",
              message: error.message || "An error occurred while clearing data",
            });
          })
          .finally(() => {
            this.loading = false;
            this.refreshData();
          });
      });
    },
  },
  apollo: {
    getDevCenterLogs() {
      this.loading = true;
      return {
        query: gql`
          query {
            Bot {
              getDevCenterLogs
            }
          }
        `,
        fetchPolicy: "network-only",
        update(data) {
          this.pagedData = _.get(data, "Bot.getDevCenterLogs", []);
          this.totalRecordCount = this.pagedData.length;
          this.loading = false;
        },
        error(error) {
          this.loading = false;
          this.$notify.error({
            title: "Error",
            position: "bottom-right",
            message: error.message || "Error fetching data",
          });
        },
      };
    },
  },
};
</script>

<style scoped lang="scss">
@import "../assets/scss/colors.scss";

table.flatten {
  font-family: arial, sans-serif;
  border-collapse: collapse;
  width: 100%;
}

table.flatten td,
table.flatten th {
  border: 1px solid $color-dark;
  text-align: left;
  padding: 4px 10px;
  background-color: transparent;
}

table.flatten tr:nth-child(even) {
  background-color: $color-light;
}
</style>
