<template>
  <section class="section">
    <p>
      <i><small>Connected agents</small></i>
    </p>
    <el-select
      clearable
      v-model="filterDepartment"
      v-if="isHandoverRoutingByDepartmentEnabled && departments.length > 1"
      placeholder="Filter by department"
      style="margin-bottom: 10px"
      size="mini"
      @clear="filterDepartment = null"
      filterable
    >
      <el-option
        v-for="department in departments"
        :key="department"
        :label="department"
        :value="department"
      ></el-option>
    </el-select>

    <el-table
      stripe
      border
      :data="agentsList"
      size="mini"
      ref="agents-connection-table"
      style="width: 100%; max-height: 40vh; overflow: scroll"
      empty-text="No record found."
    >
      <el-table-column
        property="status"
        label="STATUS"
        width="80"
        align="center"
        class-name="is-paddingless"
      >
        <template slot-scope="scope">
          <span
            v-if="scope.row.status"
            class="status-dot"
            :class="{ 'is-online': scope.row.status === 'ONLINE' }"
          ></span>
          <span v-else>
            <i class="has-text-danger el-icon-remove"></i>
          </span>
        </template>
      </el-table-column>
      <el-table-column class-name="is-paddingless" property="email" label="EMAIL">
        <template slot-scope="scope">
          <span style="white-space: wrap; overflow: hidden; text-overflow: ellipsis">
            {{ scope.row.email }}
          </span>
        </template>
      </el-table-column>

      <el-table-column
        v-if="isHandoverRoutingByDepartmentEnabled"
        class-name="is-paddingless"
        property="department"
        label="DEPARTMENTS"
      >
        <template slot-scope="scope">
          <i v-if="scope.row.departments.length === 0" style="font-size: 9px" class="has-text-dark">
            No department assigned.
          </i>
          <el-tag
            v-for="department in scope.row.departments.filter((item) => item)"
            :key="`${scope.row.email}-${department}`"
            type="primary"
            style="margin: 3px 3px 3px 0; font-size: 9px"
            size="mini"
          >
            {{ department }}
          </el-tag>
        </template>
      </el-table-column>

      <el-table-column
        class-name="is-paddingless"
        property="attending"
        label="ATTENDING"
        width="100"
        align="center"
      >
        <template slot-scope="scope">
          <strong>{{
            chatLimit > 0 ? `${scope.row.attending} / ${chatLimit}` : scope.row.attending
          }}</strong>
        </template>
      </el-table-column>
    </el-table>

    <template v-if="isShowAgentsWithUnresolvedChatEnabled">
      <el-divider></el-divider>

      <p>
        <i><small>Agents with unresolved chat</small></i>
      </p>

      <el-table
        stripe
        border
        :data="agentsWithUnresolvedChat"
        size="mini"
        ref="agents-connection-table"
        style="width: 100%; max-height: 40vh; overflow: scroll"
        empty-text="No record found."
      >
        <el-table-column
          property="status"
          label="STATUS"
          width="80"
          align="center"
          class-name="is-paddingless"
        >
          <template slot-scope="scope">
            <span
              v-if="scope.row.status"
              class="status-dot"
              :class="{ 'is-online': scope.row.status === 'ONLINE' }"
            ></span>
            <span v-else>
              <i class="has-text-danger el-icon-remove"></i>
            </span>
          </template>
        </el-table-column>
        <el-table-column class-name="is-paddingless" property="email" label="EMAIL">
          <template slot-scope="scope">
            <span style="white-space: nowrap; overflow: hidden; text-overflow: ellipsis">
              {{ scope.row.email }}
            </span>
          </template>
        </el-table-column>

        <el-table-column
          class-name="is-paddingless"
          property="attending"
          label="ATTENDING"
          width="100"
          align="center"
        >
          <template slot-scope="scope">
            <strong>
              {{ chatLimit > 0 ? `${scope.row.attending} / ${chatLimit}` : scope.row.attending }}
            </strong>
          </template>
        </el-table-column>
      </el-table>
    </template>
  </section>
</template>

<script>
import _ from "lodash";

export default {
  name: "AgentsStatusTable",
  props: ["agentsConnection"],
  data() {
    return {
      filterDepartment: null,
      currentTab: "connected_agents",
    };
  },
  computed: {
    /**
     * @description Extract uniq department from current agents list
     * @return {string[]}
     */
    departments() {
      return _.chain(this.agentsConnection)
        .map((agent) => agent.departments)
        .flatten()
        .uniq()
        .value();
    },

    agentsList() {
      if (!this.filterDepartment) {
        return this.agentsConnection;
      }
      return this.agentsConnection.filter((agent) =>
        _.includes(agent.departments, this.filterDepartment)
      );
    },

    agentsWithUnresolvedChat() {
      return _.filter(this.agentsList, (agent) => agent.attending > 0);
    },

    /**
     * @description Get agent chat limit count
     * @return {number}
     */
    chatLimit() {
      return _.get(this.$store.state, "modules.handover.chat_limit", 0);
    },

    /**
     * @description Is handover by department enabled
     * @return {boolean}
     */
    isHandoverRoutingByDepartmentEnabled() {
      return _.get(this.$store.state, "modules.handover.handoverRouting.byDepartment", false);
    },

    /**
     * @description Has show agents with unresolved chat settings enabled
     * @return {boolean}
     */
    isShowAgentsWithUnresolvedChatEnabled() {
      return _.get(
        this.$store.state,
        "modules.handover.livechatStatusMonitor.showAgentsWithUnresolvedChat",
        false
      );
    },
  },
};
</script>

<style scoped lang="scss">
@import "../../../assets/scss/colors.scss";

.status-dot {
  display: inline-block;
  width: 10px;
  height: 10px;
  border: 2px solid white;
  border-radius: 50px;
  background-color: $color-dark;
  margin-top: 8px;
}

.status-dot.is-online {
  background-color: $color-success;
}

.status-dot.is-busy {
  background-color: $color-danger;
}

.section {
  max-height: 80vh;
}
</style>
